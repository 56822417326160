
import {computed, defineComponent, reactive, ref, watch} from "vue";
import {ACTIVITY_STATUS, ACTIVITY_TYPE, CALL_CATEGORY, CURRENCY} from "@/core/config/Constant";
import Froala from "@/components/base/editor/Froala.vue";
import ActivityService from "@/core/services/ActivityService";
import DateTimePicker from "@/components/base/datetime/DateTimePicker.vue";
import store from "@/store";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import DropZoneUpload from "@/components/base/upload/DropZoneUpload.vue";
import DocumentService from "@/core/services/DocumentService";
import BaseForm from "@/components/base/form/BaseForm.vue";
import TemplateListSelect from "@/components/common/TemplateListSelect.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import ContactService from "@/core/services/ContactService";
import {IFilter} from "@/core/entity/IFilter";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import BaseCheckBox from "@/components/base/checkbox/BaseCheckBox.vue";

export default defineComponent({
  name: "FormActivity",
  components: {
    BaseCheckBox,
    BaseSelect,
    FormErrorMessage,
    UserListSelect,
    Field,
    TemplateListSelect,
    BaseForm, DropZoneUpload, BaseRadio, DateTimePicker, Froala
  },
  props: {
    activity: {type: Object, required: true}
  },
  emits: ['onSavedActivity', 'onUpdatedActivity'],
  setup(props) {
    const contacts = ref<any[]>([]);

    const submitting = ref(false);
    const activityProps = ref(props.activity)
    if (!props.activity.id) {
      activityProps.value.notifies = [];
    }
    const state = reactive<any>({
      model: activityProps
    })
    if (activityProps.value.cazeId && activityProps.value.type === 'RESERVE') {
      const caze = computed(() => store.state.CaseModule.case)
      state.model.currency = caze.value.reserve.currency
      state.model.amount = caze.value.reserve.value
    }
    if (activityProps.value.claimId && activityProps.value.type === 'RESERVE') {
      const claim = computed(() => store.state.ClaimModule.claim)
      state.model.currency = claim.value.reserve.currency
      state.model.amount = claim.value.reserve.value
    }
    watch(() => activityProps.value.type, (cb) => {
      if (activityProps.value.claimId && cb === 'RESERVE') {
        const claim = computed(() => store.state.ClaimModule.claim)
        state.model.currency = claim.value.reserve.currency
        state.model.amount = claim.value.reserve.value
      }
      if (activityProps.value.cazeId && cb === 'RESERVE') {
        const caze = computed(() => store.state.CaseModule.case)
        state.model.currency = caze.value.reserve.currency
        state.model.amount = caze.value.reserve.value
      }
    })
    watch(() => props.activity, (cb) => {
      state.value.model = Object.assign(state.model, cb)
    })
    const onClear = () => {
      state.model = activityProps
    }
    const currentId = computed(() => store.state.TemplateModule.clientId);
    const loadContacts = (params) => {
      const contactFilter = ref<IFilter>({
        paginationPage: 1,
        paginationSize: 100,
        key: {search: '', ep: ''},
      })
      contactFilter.value.key = params;
      ContactService.findAll<any>(contactFilter.value).then(res => {
          contacts.value = res.data;
      });
    }

    return {
      loadContacts,
      submitting,
      ACTIVITY_TYPE,
      ACTIVITY_STATUS,
      state,
      onClear,
      CURRENCY,
      currentId,
      contacts,
      CALL_CATEGORY,
    }
  },
  methods: {
    onTemplateSelect(item) {
      this.state.model.note = item.body
      this.state.model.title = item.subject
    },
    onFileSelected(file) {
      const data = {file: file}
      this.submitting = true;
      DocumentService.msg(data).then(res => {
        this.state.model.note = res.bodyHtml;
        this.state.model.title = res.subject
      }).finally(() => {
        this.submitting = false
      })
    },

    onSubmit() {
      this.submitting = true;
      if (!this.activity.id) {
        ActivityService.create(this.state.model).then(res => {
          this.$emit('onSavedActivity', res)
        }).finally(() => {
          this.submitting = false
        })
      } else {
        ActivityService.update(this.activity.id, this.state.model).then(res => {
          this.$emit('onUpdatedActivity', res)
        }).finally(() => {
          this.submitting = false
        })
      }
    }
  }
})
